import { Component } from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

@Component({
  selector: 'app-root',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  chatBoxNew = false;
  title = 'cviewlanding';
  onActivate(event) {
    window.scroll(0,0);
  }
  location: Location;
  constructor(location: Location) {
    this.location = location;
  }
} 
