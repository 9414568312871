import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Datepicker module
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import {TranslateModule} from '@ngx-translate/core';

// chat box
// import { ZohoSalesiqComponent } from './pages/zoho-saleiq/zoho-salesiq.component'

import {Ng2PageScrollModule} from 'ng2-page-scroll';

@NgModule({
  declarations: [
    AppComponent,
    // ZohoSalesiqComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,  
    HttpClientModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    TranslateModule.forRoot(),
    Ng2PageScrollModule,
    // ZohoSalesiqComponent
  ],
  // providers: [{
  //   provide: LocationStrategy, 
  //   useClass: HashLocationStrategy
  // }],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { 
  BsDatepickerModule: any
}
